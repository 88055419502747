export function useIsMobileView(mobileBreakpoint: string) {
    const isMobileView = ref(true);

    function checkIfMobileView() {
        if (import.meta.client) {
            const userAgent = navigator.userAgent;
            const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
            if (mobileRegex.test(userAgent)) {
                isMobileView.value = true;
            } else {
                isMobileView.value = !window.matchMedia(`(min-width: ${mobileBreakpoint})`).matches;
            }
        }
    }

    onBeforeMount(() => {
        checkIfMobileView();
    });

    onMounted(() => {
        window.addEventListener('resize', checkIfMobileView);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', checkIfMobileView);
    });

    return { isMobileView };
}
